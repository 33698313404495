import React, { useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import "./ForecastStyles.css";
import ForecastWindow from "./ForecastDataDisplay";
import DailyForecastDisplay from "./DailyForecastDisplay";

export default function ShowForecast({ bypassPopup = false, loc = null }) {
  const forecastRef = useRef(null);
  const rootRef = useRef(null);

  const [showDailyForecast, setShowDailyForecast] = useState(bypassPopup); // Show forecast immediately if bypassPopup is true
  const [showHourlyForecast, setShowHourlyForecast] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  function showDailyForecastHandler() {
    setShowDailyForecast(true);
  }

  function showHourlyForecastHandler(date) {
    setSelectedDate(date);
    setShowHourlyForecast(true);
  }

  useEffect(() => {
    if (showDailyForecast || showHourlyForecast) {
      if (!forecastRef.current) {
        forecastRef.current = document.createElement("div");
        forecastRef.current.className = "forecast-container";
        document.body.appendChild(forecastRef.current);

        rootRef.current = createRoot(forecastRef.current);
      }

      rootRef.current.render(
        <>
          {showDailyForecast && (
            <DailyForecastDisplay
              onShowHourlyForecast={showHourlyForecastHandler}
              onClose={() => setShowDailyForecast(false)}
              loc={loc}
            />
          )}
          {showHourlyForecast && (
            <ForecastWindow
              selectedDate={selectedDate}
              onClose={() => setShowHourlyForecast(false)}
            />
          )}
        </>
      );
    } else {
      if (forecastRef.current) {
        forecastRef.current.remove();
        forecastRef.current = null;
        rootRef.current = null;
      }
    }
  }, [showDailyForecast, showHourlyForecast, selectedDate]);

  return bypassPopup ? null : (
    <div>
      <button className="forecastbutton" onClick={showDailyForecastHandler}>
        Show Forecast
      </button>
    </div>
  );
}
