import React, { useState, useRef, useEffect } from "react";
import ScrollableContainer from "./ScrollableContainer";
import GenerateForecastData from "./GetForecastPaginate";
import "./ForecastStyles.css";
import DatePicker from "react-datepicker";
import ForecastModelDropDown from "./ForecastModelDropDown";
import "react-datepicker/dist/react-datepicker.css";
import "./ForecastDatePickerStyles.css";

import SetColorScale from "../SetColorScale";

function ForecastWindow({ onClose, selectedDate }) {

  const [selectedForecastDate, setSelectedForecastDate] = useState(selectedDate);
  const [selectedForecastModel, setSelectedForecastModel] = useState("gfs");
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const ref = useRef(null);

  const conditionIcons = {
    Sunny: "./assets/forecast/Sunny.svg",
    SunnyHot: "./assets/forecast/SunnyHot.svg",
    SunnyCold: "./assets/forecast/SunnyCold.svg",
    Cloudy: "./assets/forecast/Cloudy.svg",
    PartlyCloudy: "./assets/forecast/PartlyCloudyBlush.svg",
    PartlyCloudyCold: "./assets/forecast/PartlyCloudyCold.svg",
    Snowy: "./assets/forecast/Snowy.svg",
    Stormy: "./assets/forecast/Stormy.svg",
    Sleety: "./assets/forecast/Sleety.svg",
    Mix: "./assets/forecast/Mix.svg",
    MoonCloudy: "./assets/forecast/MoonCloudy.svg",
    Moony: "./assets/forecast/Moony.svg",
    RainHeavy: "./assets/forecast/RainHeavy.svg",
    RainLight: "./assets/forecast/RainLight.svg",
    Rain: "./assets/forecast/Rain.svg",
    Snow: "./assets/forecast/Snow.svg",
    Sleet: "./assets/forecast/Sleet.svg",
    FrzRain: "./assets/forecast/FrzRain.svg",
    FrzRainLight: "./assets/forecast/FrzRainLight.svg",
  };

  const {
    weatherdata,
    handleNextDay,
    handlePreviousDay,
    isLastDay,
    isLoading,
  } = GenerateForecastData(
    selectedForecastDate,
    setSelectedForecastDate,
    selectedForecastModel
  );

  // If parent changes selectedDate later, update local state
  useEffect(() => {
    if (selectedDate) {
      setSelectedForecastDate(selectedDate);
    }
  }, [selectedDate]);

  const onMouseDown = (e) => {
    setDragging(true);
    ref.current.style.cursor = "grabbing";
    // Calculate the offset here
    const rect = ref.current.getBoundingClientRect();
    setOffset({
      x: e.clientX,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseUp = () => {
    setDragging(false);
    ref.current.style.cursor = "grab";
  };

  const commonMouseMove = (e, dragging, offset, ref) => {
    if (dragging) {
      e.stopPropagation();
      const x = e.clientX - offset.x;
      const y = e.clientY - offset.y;
      ref.current.style.left = `${x}px`;
      ref.current.style.top = `${y}px`;
    }
  };

  const colorScaleInfo = SetColorScale(
    -14,
    116,
    "temperature",
    "discrete",
    "static"
  );

  const handleDateChange = (date) => {
    setSelectedForecastDate(date);
  };

  useEffect(() => {
    const onMouseUp = () => handleMouseUp();

    const onMouseMove = (e) => commonMouseMove(e, dragging, offset, ref);

    // Listen to mousemove and mouseup on the document
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      // Cleanup
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragging, offset, ref]); // Added 'offset' and 'ref' to dependencies

  function handleClose() {
    onClose();
  }

  return (
    <div className="forecastWindow">
      <div ref={ref} className="forecastpopup">
      <div className="descriptioncontainer">
          <div>
            <button className="close-button-hourly" onClick={handleClose}>
              x
            </button>
          </div>
          <div className="fdate">
            {selectedForecastDate.toLocaleDateString("en-US", {
              weekday: "long",
              month: "2-digit",
              day: "2-digit",
            })}
          </div>
          <div className="dropdown_forecast_model">
            <ForecastModelDropDown
              setSelectedForecastModel={setSelectedForecastModel}
            />
          </div>
          <div className="descrip_param">Temp(°F):</div>
          <div className="descrip_param">Precip(″):</div>
          <div className="descrip_param">Wind (mph):</div>
          <div className="descrip_param">Gusts (mph):</div>
          <div className="descrip_param">CloudCover (%):</div>
          <div className="descrip_param">Model Run:</div>
        </div>
        <div className="forecastDatePicker">
          <DatePicker
            selected={selectedForecastDate}
            onChange={handleDateChange}
            minDate={new Date()}
            maxDate={new Date(
              new Date().setHours(new Date().getHours() + 384)
            )}
            dateFormat="MM/d/yyyy"
          />
        </div>
        <div>
          <button
            className="nextday"
            onClick={handleNextDay}
            disabled={isLastDay}
          >
            {">"}
          </button>
          <button className="prevday" onClick={handlePreviousDay}>
            {"<"}
          </button>
        </div>
        <div
          className="forecastcontainer"
          // Giving drag drop ability inside forecast container
          onMouseDown={(e) => {
            onMouseDown(e);
            setPosition({ x: e.clientX, y: e.clientY });
          }}
          onMouseUp={handleMouseUp}
          onMouseMove={(e) => commonMouseMove(e, dragging, offset, ref)}
        >
          <ScrollableContainer>
            {weatherdata.length > 0 ? (
              weatherdata.map(
                ({
                  time,
                  temp,
                  precip,
                  wind,
                  gusts,
                  clouds,
                  model_run,
                  condition,
                }) => (
                  <div
                    className="forecastcard"
                    key={time}
                    style={{ display: "inline-block" }}
                  >
                    <div className="time">{time}</div>
                    {conditionIcons[condition] ? (
                      <img
                        className="image"
                        src={conditionIcons[condition]}
                        alt={condition}
                      />
                    ) : (
                      <div>No image available for condition: {condition}</div>
                    )}
                    <div className="temperatureContainerHours">
                      <div className="tempparam">{temp}°</div>
                      <div
                        className="temperatureLine"
                        style={{
                          backgroundColor: colorScaleInfo.colorScale(temp),
                          width: "70px",
                        }}
                      ></div>
                    </div>
                    <div className="parameters">{precip}″</div>
                    <div className="parameters">
                      {wind} <span style={{ fontSize: "0.7em" }}>mph</span>
                    </div>
                    <div className="parameters">
                      {gusts} <span style={{ fontSize: "0.7em" }}>mph</span>
                    </div>
                    <div className="parameters">{clouds}%</div>
                    <div className="parameters">{model_run.substring(0, 2)}Z</div>
                  </div>
                )
              )
            ) : (
              <div className="no-data-message">
                No data available at this location for {selectedForecastModel.toUpperCase()} on{" "}
                {selectedForecastDate.toLocaleDateString("en-US", {
                  weekday: "long",
                  month: "2-digit",
                  day: "2-digit",
                })}
                . Try the next or previous day.
              </div>
            )}
          </ScrollableContainer>
        </div>
      </div>
    </div>
  );
}

export default ForecastWindow;
